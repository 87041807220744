import { FontIcon, Persona, PersonaSize } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import TableViewCell from "../common/TableViewCell";
import parsePresence from "../../utils/parsePresence";
import { Providers } from "@microsoft/mgt-element";
import { useNavigate, useParams } from "react-router-dom";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarFillIcon from "@mui/icons-material/Star";
import HelpIcon from "@mui/icons-material/Help";
import classes from "./Profile.module.css";
import Button from "../common/Button";
import CustomAppBar from "../common/CustomAppBar";
import { MailLink, PhoneLink } from "./MailLink";
import CustomSpinner from "../common/CustomSpinner";
import Snackbar from "@mui/material/Snackbar";
import { loadManager, loadUser } from "../../services/usersService";
import ErrorPage from "../errors/ErrorPage";
import { loadSchedule } from "../../services/schedule/scheduleService";
import Schedule from "../schedule/Schedule";
import { ScheduleItem } from "@microsoft/microsoft-graph-types";
import Wrapper from "../common/Wrapper";
import FavouritesService from "../../services/favourites/favouritesService";
import TeamsButton from "./TeamsButton";
import CallBackRequestButton from "./CallBackRequestButton";

class ProfileData {
  id: string;
  principalName: string;
  imageUrl: string;
  initials: string;
  presence: string;
  presenceComment: string;
  name: string;
  jobTitle: string;
  statusMessage?: string;
  outOfOfficeMessage?: string;
  mail: string;
  phones: Array<string>;
  mobilePhone?: string;

  constructor(
    id: string,
    principalName: string,
    imageUrl: string,
    initials: string,
    presence: string,
    presenceComment: string,
    name: string,
    jobTitle: string,
    mail: string,
    phones: Array<string>,
    mobilePhone?: string,
    statusMessage?: string,
    outOfOfficeMessage?: string
  ) {
    this.id = id;
    this.principalName = principalName;
    this.imageUrl = imageUrl;
    this.initials = initials;
    this.presence = presence;
    this.presenceComment = presenceComment;
    this.name = name;
    this.jobTitle = jobTitle;
    this.statusMessage = statusMessage;
    this.mail = mail;
    this.phones = phones;
    this.mobilePhone = mobilePhone;
    this.outOfOfficeMessage = outOfOfficeMessage;
  }
}

export default function Profile({}) {
  const { principalName } = useParams();
  const [data, setData] = useState<ProfileData>();
  const [schedule, setSchedule] = useState<ScheduleItem[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [snackBarText, setSnackBarText] = useState("");
  const [isFavourite, setIsFavourite] = useState(false);
  const [refresh, setRefresh] = useState<void>();
  const [managerPrincipalName, setManagerPrincipalName] = useState<string>();

  const navigate = useNavigate();

  const isEditable = principalName === undefined;

  const favouritesService = new FavouritesService();

  const handleSnackBarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSnackBarOpen(false);
  };

  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
    setSnackBarText("Copied to clipboard");
    setIsSnackBarOpen(true);
  };

  const toggleFavourite = async () => {
    if (data === undefined) {
      return;
    }
    try {
      if (isFavourite) {
        await favouritesService.removeFavourite(data.principalName);
        setIsFavourite(false);
      } else {
        await favouritesService.addFavourite(data.principalName);
        setIsFavourite(true);
      }
    } catch (error) {
      setSnackBarText("Something went wrong");
      setIsSnackBarOpen(true);
    }
  };

  const onPeersClicked = () => {
    if (data?.principalName) {
      navigate(`/subordinates/${data?.principalName}`);
    }
  };

  const onDelveProfileClicked = () => {
    if (data?.id) {
      window.open(`https://eur.delve.office.com/?u=${data?.id}`);
    }
  };

  const onLogOutClicked = () => {
    if (Providers.globalProvider.logout !== undefined) {
      Providers.globalProvider.logout();
    }
  };

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      setData(undefined);
      setSchedule(undefined);
      setManagerPrincipalName(undefined);
      const managerPromise = async () => {
        try {
          const manager = await loadManager(principalName);
          setManagerPrincipalName(manager.principalName);
        } catch (error) {}
      };
      const dataPromise = async () => {
        try {
          const user = await loadUser(principalName);
          const presenceComment = "";
          const imageUrl =
            principalName === undefined
              ? "/backend/api/v1.0/me/photo/$value"
              : `/backend/api/v1.0/users/${principalName}/photo/$value`;

          const data = new ProfileData(
            user.id,
            user.principalName,
            imageUrl,
            user.initials,
            user.presence,
            presenceComment,
            user.displayName,
            user.jobTitle,
            user.email,
            user.phones,
            user.mobilePhone,
            user.statusMessage,
            user.outOfOfficeMessage
          );
          setData(data);

          if (principalName) {
            const favourites = await favouritesService.loadFavourites();
            const isFavourite = favourites.includes(user.principalName);
            setIsFavourite(isFavourite);
          }

          const scheduleItems = await loadSchedule(
            new Date(),
            user.principalName
          );
          setSchedule(scheduleItems);
        } catch (error) {}
      };
      await Promise.all([managerPromise(), dataPromise()]);
      setIsLoading(false);
    }
    fetch();
  }, [principalName, refresh]);

  if (isLoading) {
    return <CustomSpinner />;
  }

  if (!data || !schedule) {
    return (
      <ErrorPage
        message="Something went wrong"
        buttonText="Try again"
        buttonAction={setRefresh}
      />
    );
  } else {
    return (
      <>
        <div className="screen">
          <CustomAppBar
            showBackButton={!isEditable}
            rightElement={
              <div className={classes.navbarButtons}>
                <a href={process.env.REACT_APP_HELP_LINK} target="_blank">
                  <HelpIcon className={classes.help} fontSize="large" />
                </a>
                {isEditable ? undefined : (
                  <div onClick={toggleFavourite}>
                    {isFavourite ? (
                      <StarFillIcon fontSize="large" />
                    ) : (
                      <StarOutlineIcon fontSize="large" />
                    )}
                  </div>
                )}
              </div>
            }
          />

          <div className={classes.profile}>
            <div className={classes.avatar}>
              <Persona
                hidePersonaDetails={true}
                imageUrl={data.imageUrl}
                imageInitials={data.initials}
                size={PersonaSize.size120}
                presence={parsePresence(data.presence)}
              />
            </div>
            <div className={classes.name}>{data.name}</div>
            <div className={classes.jobTitle}>{data.jobTitle}</div>
            <div className={classes.content}>
              {!isEditable && (
                <div className={classes.contacts}>
                  <Wrapper title="Contact via Teams">
                    <div className={classes.teamsButtons}>
                      <div className={classes.callButtons}>
                        <TeamsButton
                          href={`https://teams.microsoft.com/l/chat/0/0?users=${data.principalName}`}
                          iconName="officechat"
                        />
                        <TeamsButton
                          href={`https://teams.microsoft.com/l/call/0/0?users=${data.principalName}`}
                          iconName="phone"
                        />
                        <TeamsButton
                          href={`https://teams.microsoft.com/l/call/0/0?users=${data.principalName}&withVideo=true`}
                          iconName="video"
                        />
                      </div>
                      <CallBackRequestButton
                        principalName={data.principalName}
                        completion={(success) => {
                          success
                            ? setSnackBarText("Message sent")
                            : setSnackBarText("Something went wrong");
                          setIsSnackBarOpen(true);
                        }}
                      />
                    </div>
                  </Wrapper>
                </div>
              )}
              {data.statusMessage && data.statusMessage.length > 0 && (
                <TableViewCell
                  icon={<FontIcon iconName="message" />}
                  content={
                    <div
                      dangerouslySetInnerHTML={{ __html: data.statusMessage }}
                    />
                  }
                />
              )}
              {data.outOfOfficeMessage &&
                data.outOfOfficeMessage.length > 0 && (
                  <TableViewCell
                    icon={<FontIcon iconName="outOfOffice" />}
                    content={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.outOfOfficeMessage,
                        }}
                      />
                    }
                  />
                )}
              <div className={classes.cellButton}>
                <TableViewCell
                  icon={<FontIcon iconName="mail" />}
                  content={<MailLink mail={data.mail} text={data.mail} />}
                  accessoryIcon={
                    <div className={classes.accessoryButton}>
                      <FontIcon iconName="copy" />
                    </div>
                  }
                  onAccessoryClick={(_) => copyToClipboard(data.mail)}
                />
              </div>
              {data.mobilePhone && (
                <div className={classes.cellButton}>
                  <TableViewCell
                    icon={<FontIcon iconName="cellphone" />}
                    content={
                      <PhoneLink
                        phone={data.mobilePhone}
                        text={data.mobilePhone}
                      />
                    }
                    accessoryIcon={
                      <div className={classes.accessoryButton}>
                        <FontIcon iconName="copy" />
                      </div>
                    }
                    onAccessoryClick={(_) =>
                      copyToClipboard(data.mobilePhone ?? "")
                    }
                  />
                </div>
              )}
              {data.phones.map((phone) => (
                <div className={classes.cellButton}>
                  <TableViewCell
                    icon={<FontIcon iconName="phone" />}
                    content={<PhoneLink phone={phone} text={phone} />}
                    accessoryIcon={
                      <div className={classes.accessoryButton}>
                        <FontIcon iconName="copy" />
                      </div>
                    }
                    onAccessoryClick={(_) => copyToClipboard(phone)}
                  />
                </div>
              ))}
              {managerPrincipalName && (
                <div className={classes.cellButton} onClick={onPeersClicked}>
                  <TableViewCell
                    icon={<FontIcon iconName="people" />}
                    content={"Peers"}
                    accessoryIcon={
                      <div className={classes.accessoryButton}>
                        <FontIcon iconName="chevronright" />
                      </div>
                    }
                  />
                </div>
              )}
              <div className={classes.cellButton} onClick={onDelveProfileClicked}>
                  <TableViewCell
                    icon={<FontIcon iconName="UserOptional" />}
                    content={"Delve Profile"}
                    accessoryIcon={
                      <div className={classes.accessoryButton}>
                        <FontIcon iconName="chevronright" />
                      </div>
                    }
                  />
                </div>
            </div>
            {schedule && <Schedule scheduleItems={schedule} />}
          </div>
          <div className={classes.bottomButtons}>
            {isEditable && <Button onClick={onLogOutClicked} text="Log out" />}
          </div>
          <Snackbar
            open={isSnackBarOpen}
            autoHideDuration={1000}
            onClose={handleSnackBarClose}
            message={snackBarText}
          />
        </div>
      </>
    );
  }
}
